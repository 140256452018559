<template>
  <div>
    <CCard>
        <CCardHeader color="info" text-color="white">
          Vendor
        </CCardHeader>
        <CCardBody>
<CRow>
      <CCol sm="5">
        <CRow>
          <CCol sm="10">
            <CInput
                data-sel="ipt-sname"
                v-model="vendor.shortName"
                v-on:change="updateItem"
                placeholder="Enter name"
                description="Display name"
                :is-valid="notNull"
                tooltip-feedback
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.shortName? tooltips.shortName: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="10">
            <CInput
                data-sel="ipt-name"
                v-model="vendor.name"
                v-on:change="updateItem"
                placeholder="Enter full company name"
                description="Name"
                :is-valid="notNull"
                tooltip-feedback
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.name? tooltips.name: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="5">
            <multiselect
                data-sel="slct-opin"
                :disabled="!allowEdit"
                v-model="vendor.operatesIn"
                :options='countries? countries.data? countries.data: []: []'
                @input="updateItem"
                :show-labels="true"
                label="englishName"
                track_by="englishName"
                :close-on-select="true"
                :allow-empty="false"
                placeholder="Operates in"
                deselect-label="You must select a country"
            />
            <CRow
                v-c-tooltip="{content: tooltips.operatesIn? tooltips.operatesIn: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Operates in</small>

            </CRow>
          </CCol>
          <CCol sm="5">
            <CInput
                data-sel="ipt-exid"
                v-model="vendor.externalId"
                v-on:change="updateItem"
                :isValid="validExternalID"
                invalid-feedback="Please enter a valid ID using upper case letters and digits only. Length is 3."
                placeholder="Enter external ID"
                description="Vendor external ID"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.externalId? tooltips.externalId: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="2">
        <CRow>
          <CImg
              :src="vendor.logo? vendor.logo.hrefOrig: null"
              block
              class="mb-2"
              width="80%"
          />
        </CRow>
        <CRow v-if="allowEdit">
          <CCol sm="12">
            <CInputFile
                data-sel="ipt-logo"
                v-on:change="uploadLogo"
                placeholder="Upload logo"
                description="Vendor logo"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.logo? tooltips.logo: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="3">
        <CCard body-wrapper>
          <CCardHeader>
            <h6>Active</h6>
          </CCardHeader>
          <CCardBody style="padding: 0.5rem">
            <CRow class="switch-row">
              <CCol class="switch-prefix-col">
                fh.de (active)
              </CCol>
              <CCol class="switch-col" data-sel="btn-fhactv">
                <CSwitch
                    :checked.sync="vendor.activeFh"
                    v-on:update:checked="updateItem"
                    color="info"
                    :disabled="!allowEdit"
                    v-c-tooltip="{content: tooltips.activeFh? tooltips.activeFh: '', delay: tooltipDelay}"
                />
              </CCol>
            </CRow>
            <CRow class="switch-row">
              <CCol class="switch-prefix-col">
                fh.de (hidden)
              </CCol>
              <CCol class="switch-col" data-sel="btn-fhhidd">
                <CSwitch
                    :checked.sync="vendor.hidden"
                    v-on:update:checked="updateItem"
                    color="info"
                    :disabled="!allowEdit"
                    v-c-tooltip="{content: tooltips.hidden? tooltips.hidden: '', delay: tooltipDelay}"
                />
              </CCol>
            </CRow>
            <CRow class="switch-row">
              <CCol class="switch-prefix-col">
                MS config
              </CCol>
              <CCol class="align-content-end text-right">
                <CButton
                    :href="wagtailUrl"
                    target="_blank"
                    rel="noreferrer noopener"
                    color="info"
                    variant="outline"
                >
                  Wagtail
                </CButton>
              </CCol>
            </CRow>
            <CRow class="switch-row">
              <CCol class="switch-prefix-col">
                PApp
              </CCol>
              <CCol class="switch-col" data-sel="btn-pappactv">
                <CSwitch
                    :checked.sync="vendor.activePapp"
                    v-on:update:checked="updateItem"
                    color="info"
                    :disabled="!allowEdit"
                    v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="2">
        <CCard body-wrapper>
          <CCardBody class="active-info-box">
            <b>active: true, hidden: false</b><br>
            vendor is fully visible everywhere.<br>
            <b>active: false, hidden: false</b><br>
            vendor is visible in de-activated state, only displayed in some places.<br>
            <b>active: true, hidden: true</b><br>
            vendor is not visible at all, but can be visited if you know the url. Used for onboarding.<br>
            <b>active: false, hidden: true</b><br>
            vendor is not visible at all. Used only in special cases.<br>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="2" data-sel="btn-fhq">
        <CSwitch
            :disabled="!allowEdit"
            :checked.sync="vendor.isFranchiseHq"
            v-on:update:checked="updateItem"
            color="info"
            size="lg"
            variant="opposite"
            labelOn="Yes"
            labelOff="No"
        />
        <CRow
            v-c-tooltip="{content: tooltips.isFranchiseHq? tooltips.isFranchiseHq: '', delay: tooltipDelay}"
        >
          <small class="form-text text-muted w-100">Is franchise headquarter</small>
        </CRow>
      </CCol>
      <CCol
          sm="2"
          v-if="vendor.isFranchiseHq"
          data-sel="btn-shroffs"
      >
        <CSwitch
            :disabled="!(allowEdit && vendor.isFranchiseHq)"
            :checked.sync="vendor.sharePartnerOffices"
            v-on:update:checked="updateItem"
            color="info"
            size="lg"
            variant="opposite"
            labelOn="Yes"
            labelOff="No"
        />
        <CRow
            v-c-tooltip="{content: tooltips.sharePartnerOffices? tooltips.sharePartnerOffices: '', delay: tooltipDelay}"
        >
          <small class="form-text text-muted w-100">Share partner offices</small>
        </CRow>
      </CCol>
      <CCol
          sm="10"
          v-if="!vendor.isFranchiseHq"
      >
        <multiselect
            data-sel="slct-fhq"
            :disabled="!allowEdit"
            v-model="vendor.franchiseHq"
            @input="updateItem"
            :options="this.franchiseHeadquarters ? this.franchiseHeadquarters.data? this.franchiseHeadquarters.data: []: []"
            :show-labels="true"
            label="shortName"
            track-by="shortName"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
            select-label="Select"
            deselect-label="Remove"
            placeholder="Franchise Headquarter"
            v-on:search-change="updateVendorQ"
        />
        <CRow
            v-c-tooltip="{content: tooltips.franchiseHq? tooltips.franchiseHq: '', delay: tooltipDelay}"
        >
          <small class="form-text text-muted w-100">Franchise Headquarter</small>
        </CRow>
      </CCol>
    </CRow>
        </CCardBody>
    </CCard>

    <FormDetailSection
        data-sel="btn-sctn-cnt"
        section="Address & Contact"
        v-bind:showSection="showContact"
        v-on:toggle="toggleContact"
    />
    <CCollapse
        :show.sync="showContact"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Address & Contact Details</CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">Address</CCardHeader>
            <CCardBody>
              <AddressForm
                  v-bind:address-in="address"
                  v-bind:display-additional-address-line="true"
                  v-on:update-item="updateAddress"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">Contacts</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                      data-sel="ipt-email"
                      v-model="vendor.contactEmail"
                      :isValid="validEmail"
                      v-on:change="updateItem"
                      placeholder="Enter public email"
                      description="Public email"
                      :readonly="!allowEdit"
                      @input="vendor.contactEmail = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.contactEmail? tooltips.contactEmail: '', delay: tooltipDelay}"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                      data-sel="ipt-phone"
                      v-model="vendor.contactPhone"
                      v-on:change="updateItem"
                      placeholder="Enter phone number"
                      description="Phone"
                      :readonly="!allowEdit"
                      @input="vendor.contactPhone = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.contactPhone? tooltips.contactPhone: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">Anonymisation Requests</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <multiselect
                      data-sel="slct-anon-emails"
                      :disabled="!allowEdit"
                      v-model="vendor.anonymizationRequestEmails"
                      @input="updateItem"
                      :options="anonymizationRequestEmails"
                      :taggable="true"
                      tag-placeholder="Add this email"
                      placeholder="Add anonymization request email recipients"
                      :multiple="true"
                      @tag="addAnonymizationRequestEmail"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.anonymizationRequestEmails? tooltips.anonymizationRequestEmails: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Anonymization request emails
                      recipients</small>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>


        </CCardBody>
      </CCard>
    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-brnd"
        section="Branding & Onpage Display"
        v-bind:showSection="showBranding"
        v-on:toggle="toggleBranding"
    />
    <CCollapse
        :show.sync="showBranding"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Branding & Onpage Display Settings</CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">
              <div class="badge-pw">PW</div>
              Branding Settings for PW
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="4">
                  <multiselect
                      data-sel="slct-brndpln"
                      :disabled="!allowEdit"
                      v-model="vendor.brandingPlan"
                      :options="['basic', 'pro', 'premium']"
                      @input="updateItem"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select branding plan"
                      deselect-label="You must select a branding plan"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.brandingPlan? tooltips.brandingPlan: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Branding plan</small>
                  </CRow>
                </CCol>
                <CCol sm="4" data-sel="btn-rgnbrnd">
                  <CRow class="justify-content-center switch"
                  >
                    <CSwitch
                        :checked.sync="vendor.enableRegionalBranding"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                        :disabled="!allowEdit"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.enableRegionalBranding? tooltips.enableRegionalBranding: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Regional branding</small>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <CRow>
                    <CCol sm="12">
                      <CImg
                          :src="vendor.logo? vendor.logo.hrefOrig: null"
                          :thumbnail="true"
                          :height="vendor.logo? vendor.logo.hrefOrig? '100%': '100px': '100px'"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="allowEdit">
                    <CCol sm="12">
                      <CInputFile
                          v-on:change="uploadLogo"
                          placeholder="Upload logo"
                          description="Vendor logo"
                          :readonly="!allowEdit"
                          v-c-tooltip="{content: tooltips.logo? tooltips.logo: '', delay: tooltipDelay}"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <CRow>
                    <CCol sm="12">
                      <CImg
                          :src="vendor.officeProfilePicture? vendor.officeProfilePicture.hrefOrig: null"
                          :thumbnail="true"
                          width="50%"
                          :height="vendor.officeProfilePicture? vendor.officeProfilePicture.hrefOrig? '100%': '100px': '100px'"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="allowEdit">
                    <CCol sm="12">
                      <CInputFile
                          v-on:change="uploadOfficeProfilePicture"
                          placeholder="Upload office profile picture"
                          description="Office profile picture"
                          :readonly="!allowEdit"
                          v-c-tooltip="{content: tooltips.officeProfilePicture? tooltips.officeProfilePicture: '', delay: tooltipDelay}"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <CInput
                      data-sel="ipt-ctlg"
                      v-model="vendor.catalogueLink"
                      v-on:change="updateItem"
                      placeholder="Enter link to catalogue"
                      description="Link to catalogue"
                      :readonly="!allowEdit"
                      @input="vendor.catalogueLink = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.catalogueLink? tooltips.catalogueLink: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"><div class="badge-pw">PW</div>Top Placement Settings for PW</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="3">
              <br/>
              <CRow class="justify-content-center switch" data-sel="btn-vtp"
              >
                <CSwitch
                    :checked.sync="vendor.topVendorPlacement"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                    :disabled="!allowEdit"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.topVendorPlacement? tooltips.topVendorPlacement: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Vendor top placement</small>
              </CRow>
              <br/>
              <CRow class="justify-content-center switch" data-sel="btn-rtp"
              >
                <CSwitch
                    :checked.sync="vendor.topLocalPlacement"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                    :disabled="!allowEdit"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.topLocalPlacement? tooltips.topLocalPlacement: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Regional top placement</small>
              </CRow>
              <br/>
              <CRow class="justify-content-center switch" data-sel="btn-htp"
              >
                <CSwitch
                    :checked.sync="vendor.topLocalPlacementHouseList"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                    :disabled="!allowEdit"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.topLocalPlacementHouseList? tooltips.topLocalPlacementHouseList: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">House list top placement</small>
              </CRow>
            </CCol>
            <CCol sm="9">
              <CCard>
                <CCardHeader
                    v-c-tooltip="{content: tooltips.localPlacementRegionOverride? tooltips.localPlacementRegionOverride: '', delay: tooltipDelay}"
                >
                  Regional top placement regions
                </CCardHeader>
                <CCardBody data-sel="zips-rtp">
                  <PostalCodeSelector
                      v-bind:country="this.vendor? this.vendor.operatesIn: null"
                      v-bind:postal-codes-in="this.vendor? this.vendor.localPlacementRegionOverride: []"
                      v-on:update-item="(e) => {this.vendor.localPlacementRegionOverride = e; this.updateItem();}"
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
        >
          <CRow>

            <CCol sm="10">
              c
              {{ vendor.displayRegions ? vendor.displayRegions.length : 0 }} Display regions for PW
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody data-sel="zips-dpr">
              <CButton
                  data-sel="btn-use-ho"
                  v-if="allowEdit"
                  @click="handoverToDisplayRegions"
                  color="info"
                  variant="outline"
                  class="btn-sm"
                  v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
              >Use Handover Regions
              </CButton>
          <PostalCodeSelector
              v-bind:country="vendor? vendor.operatesIn: null"
              v-bind:postal-codes-in="vendor? vendor.displayRegions: null"
              v-on:update-item="(e) => {this.vendor.displayRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.msDisplayRegions? tooltips.msDisplayRegions: '', delay: tooltipDelay}"
        >
          <CRow>
            <CCol sm="10">
              <div class="badge-ms">MS</div>
              {{ vendor.msDisplayRegions ? vendor.msDisplayRegions.length : 0 }} display regions for MS
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody data-sel="zips-dpr">
          <PostalCodeSelector
              v-bind:country="vendor? vendor.operatesIn: null"
              v-bind:postal-codes-in="vendor? vendor.msDisplayRegions: null"
              v-on:update-item="(e) => {this.vendor.msDisplayRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.imDisplayRegions? tooltips.imDisplayRegions: '', delay: tooltipDelay}"
        >
          <CRow>
            <CCol sm="10">
              <div class="badge-im">IM</div>
              {{ vendor.imDisplayRegions ? vendor.imDisplayRegions.length : 0 }} display regions for IM
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody data-sel="zips-dpr">
          <PostalCodeSelector
              v-bind:country="vendor? vendor.operatesIn: null"
              v-bind:postal-codes-in="vendor? vendor.imDisplayRegions: null"
              v-on:update-item="(e) => {this.vendor.imDisplayRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-prtf"
        section="Portfolio"
        v-bind:showSection="showPortfolio"
        v-on:toggle="togglePortfolio"
    />
    <CCollapse
        :show.sync="showPortfolio"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Portfolio Settings</CCardHeader>
        <CCardBody>


          <CCard>
            <CCardHeader color="secondary"><div class="badge-pw">PW</div>Vendor display settings</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="4">
                  <CTextarea
                      data-sel="ipt-dscrp"
                      v-model="vendor.description"
                      rows="6"
                      v-on:change="updateItem"
                      placeholder="Add description"
                      description="Description"
                      :readonly="!allowEdit"
                      v-c-tooltip="{content: tooltips.description? tooltips.description: '', delay: tooltipDelay}"
                  />
                </CCol>
                <CCol sm="4">
                  <multiselect
                      data-sel="slct-cm"
                      :disabled="!allowEdit"
                      v-model="vendor.constructionMethods"
                      @input="updateItem"
                      :options="['prefab', 'solid', 'blockhouse']"
                      :multiple="true"
                      :searchable="false"
                      :close-on-select="false"
                      :allow-empty="true"
                      placeholder="Select construction methods"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.constructionMethods? tooltips.constructionMethods: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Construction methods</small>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <multiselect
                      data-sel="slct-expst"
                      :disabled="!allowEdit"
                      v-model="vendor.expansionStages"
                      @input="updateItem"
                      :options="['price_fitting', 'price_lego', 'price_ready']"
                      :multiple="true"
                      :searchable="false"
                      :close-on-select="false"
                      :allow-empty="true"
                      placeholder="Select expansion stages"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.expansionStages? tooltips.expansionStages: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Expansion stages</small>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-hotv"
        section="Handover"
        v-bind:showSection="showHandover"
        v-on:toggle="toggleHandover"
    />
    <CCollapse
        :show.sync="showHandover"
        :duration="1"
    >
      <CCard

      >
        <CCardHeader color="info" text-color="white">Handover Settings</CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">Unified Handover Filter Settings</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <multiselect
                      data-sel="slct-hof"
                      :disabled="!allowEdit"
                      v-model="vendor.handoverFilter"
                      @input="updateItem"
                      :options="['out_prequal_invalid_contacts', 'out_inorganic_prequal_not_reached_or_willing_filter', 'out_no_sap_found', 'out_not_reached_incomplete', 'out_inorganic_prequal_not_reached_or_willing_tc_temp']"
                      :searchable="false"
                      :multiple="true"
                      :allow-empty="true"
                      placeholder="Select handover filter"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.handoverFilter? tooltips.handoverFilter: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Handover filter</small>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">Unified Handover Assignment Settings</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="3">
                  <multiselect
                      data-sel="slct-hoipt"
                      :disabled="!allowEdit"
                      v-model="vendor.handoverAssignmentPrimaryInput"
                      @input="updateItem"
                      :options="['postal_code', 'delivery_region']"
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select handover method"
                      select-label="Select"
                      deselect-label="Required field"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.handoverAssignmentPrimaryInput? tooltips.handoverAssignmentPrimaryInput: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Handover assignment primary
                      input</small>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <CRow class="justify-content-center switch" data-sel="btn-hoiptfb">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="vendor.handoverAssignmentSecondaryInputFallback"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.handoverAssignmentSecondaryInputFallback? tooltips.handoverAssignmentSecondaryInputFallback: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Handover assignment secondary input
                      fallback</small>
                  </CRow>
                </CCol>
                <CCol sm="5">
                  <CCard color="info" text-color="white">
                    <CCardBody>
                      <CCardText>
                        The "assignment primary input" defines the prospect's field (either
                        postal code or delivery region) which is used to determine whether the
                        prospect matches the vendor's delivery regions.
                        If the "assignment secondary input fallback" is checked, then both
                        prospect fields are used to determine the match, instead of only the
                        primary input field.
                      </CCardText>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader
                v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
                color="secondary"
            >
              <div class="badge-pw">PW</div>
              {{ this.vendor.handoverRegions ? this.vendor.handoverRegions.length : 0 }} Handover regions
              for PW
            </CCardHeader>
            <CCardBody data-sel="zips-hor">
              <PostalCodeSelector
                  v-bind:country="vendor? vendor.operatesIn: null"
                  v-bind:postal-codes-in="vendor? vendor.handoverRegions: null"
                  v-on:update-item="(e) => {this.vendor.handoverRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader color="secondary"><div class="badge-pw">PW</div>Handover Formats for PW</CCardHeader>
            <CCardBody>
              <CRow class="handover-spec-row">
                <CCol sm=2>
                  <CRow class="justify-content-center switch" data-sel="btn-rep-inst">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="vendor.sendInstantReporting"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.sendInstantReporting? tooltips.sendInstantReporting: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Instant email handover</small>
                  </CRow>
                </CCol>
                <CCol sm="10" v-if="vendor.sendInstantReporting">
                  <CRow>
                    <!-- Instant email handover-->
                    <CCol sm="3">
                      <CRow class="justify-content-center switch" data-sel="btn-rep-instcsv">
                        <CSwitch
                            :disabled="!allowEdit"
                            :checked.sync="vendor.instantReportingCsv"
                            v-on:update:checked="updateItem"
                            color="info"
                            size="lg"
                            variant="opposite"
                            labelOn="Yes"
                            labelOff="No"
                        />
                      </CRow>
                      <CRow
                          v-c-tooltip="{content: tooltips.instantReportingCsv? tooltips.instantReportingCsv: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Add CSV to instant email
                          handover</small>
                      </CRow>
                    </CCol>
                    <CCol sm="3">
                      <multiselect
                          data-sel="slct-rep-inst-frmt"
                          :disabled="!allowEdit"
                          v-model="vendor.instantReportingFormat"
                          @input="updateItem"
                          :options=this.instantReportingOptions
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          placeholder="Select instant email handover format"
                          select-label="Select"
                          deselect-label="Remove"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.instantReportingFormat? tooltips.instantReportingFormat: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Instant email handover
                          format</small>
                      </CRow>
                    </CCol>
                    <CCol sm="6">
                      <multiselect
                          data-sel="slct-rep-inst-email"
                          :disabled="!allowEdit"
                          v-model="vendor.instantReportingEmails"
                          @input="updateItem"
                          :options="instantReportingEmails"
                          :taggable="true"
                          tag-placeholder="Add this email"
                          placeholder="Add instant email handover recipients"
                          :multiple="true"
                          @tag="addInstantReportingEmail"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.instantReportingEmails? tooltips.instantReportingEmails: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Instant email handover
                          recipients</small>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="handover-spec-row">
                <CCol sm="2">
                  <CRow class="justify-content-center switch" data-sel="btn-rep-api">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="vendor.instantReportingApi"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.instantReportingApi? tooltips.instantReportingApi: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Instant API handover</small>
                  </CRow>
                </CCol>
                <CCol sm="10" v-if="vendor.instantReportingApi">
                  <CRow>
                    <!-- Instant API handover-->
                    <CCol sm="3">
                      <CInput
                          data-sel="ipt-rep-api-ep"
                          :readonly="!allowEdit"
                          v-model="vendor.instantReportingApiEndpoint"
                          v-on:change="updateItem"
                          placeholder="Enter instant API handover endpoint"
                          description="Instant API handover endpoint"
                          @input="vendor.instantReportingApiEndpoint = $event !== '' ? $event : null"
                          v-c-tooltip="{content: tooltips.instantReportingApiEndpoint? tooltips.instantReportingApiEndpoint: '', delay: tooltipDelay}"
                      />
                    </CCol>
                    <CCol sm="3">
                      <multiselect
                          data-sel="slct-rep-api-pre"
                          :disabled="!allowEdit"
                          v-model="vendor.instantReportingApiPreset"
                          @input="updateItem"
                          :options=this.instantReportingApiPresetOptions
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          placeholder="Select API preset"
                          select-label="Select"
                          deselect-label="Remove"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.instantReportingApiPreset? tooltips.instantReportingApiPreset: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">API preset</small>
                      </CRow>
                    </CCol>
                    <CCol sm="3">
                      <CTextarea
                          data-sel="ipt-rep-api-head"
                          :readonly="!allowEdit"
                          v-model="vendor.instantReportingApiHeader"
                          v-on:change="updateItem"
                          placeholder="Enter instant API handover extra header"
                          description="Instant API handover extra header"
                          v-c-tooltip="{content: tooltips.instantReportingApiHeader? tooltips.instantReportingApiHeader: '', delay: tooltipDelay}"
                      />
                    </CCol>
                    <CCol sm="3">
                      <CTextarea
                          data-sel="ipt-rep-api-pl"
                          :readonly="!allowEdit"
                          v-model="vendor.instantReportingApiExtraPayload"
                          v-on:change="updateItem"
                          placeholder="Add instant API handover extra payload"
                          description="Instant API handover extra payload"
                          v-c-tooltip="{content: tooltips.instantReportingApiExtraPayload? tooltips.instantReportingApiExtraPayload: '', delay: tooltipDelay}"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="handover-spec-row">
                <CCol sm="2">
                  <CRow class="justify-content-center switch" data-sel="btn-rep-dly">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="vendor.sendDailyReporting"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.sendDailyReporting? tooltips.sendDailyReporting: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Daily email handover</small>
                  </CRow>
                </CCol>
                <CCol sm="10">
                  <CRow>
                    <!-- Daily email handover-->
                    <CCol sm="6">
                      <multiselect
                          data-sel="slct-rep-dly-frmt"
                          :disabled="!allowEdit"
                          v-model="vendor.dailyReportingFormat"
                          @input="updateItem"
                          :options="['fertighaus_csv', 'outfitter_csv', 'house_vendor_pq', 'house_vendor_pq_2020', 'house_vendor_pq_2020_so_exid','house_vendor_pq_2021_ppc', 'house_vendor_npq', 'schwoerer_csv', 'wuestenrot_csv', 'hvh_csv', 'helma_csv','wolfsystem_csv', 'planoa_csv', 'fingerhut_csv']"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          placeholder="Select daily email handover format"
                          select-label="Select"
                          deselect-label="Remove"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.dailyReportingFormat? tooltips.dailyReportingFormat: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Daily email handover
                          format</small>
                      </CRow>
                    </CCol>
                    <CCol sm="6" v-if="vendor.sendDailyReporting">
                      <multiselect
                          data-sel="slct-rep-dly-email"
                          :disabled="!allowEdit"
                          v-model="vendor.dailyReportingEmails"
                          @input="updateItem"
                          :options="dailyReportingEmails"
                          :taggable="true"
                          tag-placeholder="Add this email"
                          placeholder="Add daily email handover recipients"
                          :multiple="true"
                          @tag="addDailyReportingEmail"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.dailyReportingEmails? tooltips.dailyReportingEmails: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Daily email handover
                          recipients</small>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <CRow class="handover-spec-row">
                <CCol sm="2">
                  <CRow class="justify-content-center switch" data-sel="btn-rep-dup">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="vendor.sendLeadDuplicateReporting"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.sendLeadDuplicateReporting? tooltips.sendLeadDuplicateReporting: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Daily lead duplicate reporting</small>
                  </CRow>
                </CCol>
                <CCol sm="10">
                  <CRow>
                    <!-- Daily email handover-->
                    <CCol sm="6" v-if="vendor.sendLeadDuplicateReporting">
                      <multiselect
                          data-sel="slct-rep-dup-frmt"
                          :disabled="!allowEdit"
                          v-model="vendor.leadDuplicateReportingFormat"
                          @input="updateItem"
                          :options="['duplicate_leads_csv']"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          placeholder="Select format"
                          select-label="Select"
                          deselect-label="Remove"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.leadDuplicateReportingFormat? tooltips.leadDuplicateReportingFormat: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Daily lead duplicate reporting
                          format</small>
                      </CRow>
                    </CCol>
                    <CCol sm="6" v-if="vendor.sendLeadDuplicateReporting">
                      <multiselect
                          data-sel="slct-rep-dup-email"
                          :disabled="!allowEdit"
                          v-model="vendor.leadDuplicateReportingEmails"
                          @input="updateItem"
                          :options="leadDuplicateReportingEmails"
                          :taggable="true"
                          tag-placeholder="Add this email"
                          placeholder="Add lead duplicate email recipients"
                          :multiple="true"
                          @tag="addLeadDuplicateReportingEmail"
                      />
                      <CRow
                          v-c-tooltip="{content: tooltips.leadDuplicateReportingEmails? tooltips.leadDuplicateReportingEmails: '', delay: tooltipDelay}"
                      >
                        <small class="form-text text-muted w-100">Daily lead duplicate reporting
                          recipients</small>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader color="secondary"><div class="badge-pw">PW</div>Direct handover Settings for PW</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-hotsa">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.activateHandoverToSalesAgent"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.activateHandoverToSalesAgent? tooltips.activateHandoverToSalesAgent: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Handover to sales agent</small>
              </CRow>
            </CCol>

          </CRow>
          <CRow v-if="vendor.activateHandoverToSalesAgent">
            <CCol sm="2">
              <multiselect
                  data-sel="slct-dht"
                  :disabled="!allowEdit"
                  v-model="vendor.directHandoverTemplate"
                  @input="setDefaultDirectHandoverTemplateValue"
                  :options="['prequal_2020', 'prequal_default', 'basic']"
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select handover to sales agent email template "
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.directHandoverTemplate? tooltips.directHandoverTemplate: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Handover to sales agent email template</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CInput
                  data-sel="ipt-pdfid"
                  :readonly="!allowEdit"
                  v-model="vendor.factSheetId"
                  v-on:change="updateItem"
                  placeholder="Enter factsheet ID"
                  description="Factsheet ID"
                  @input="setDefaultFactsheetValue"
                  v-c-tooltip="{content: tooltips.factSheetId? tooltips.factSheetId: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-nocd">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.activateNoContactDesiredToSalesAgents"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.activateNoContactDesiredToSalesAgents? tooltips.activateNoContactDesiredToSalesAgents: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Activate handover no contact desired to sales
                  agent</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-rep-so">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.sendDailyReportingToSalesOffices"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.sendDailyReportingToSalesOffices? tooltips.sendDailyReportingToSalesOffices: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Daily email handover to sales offices</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"><div class="badge-ms">MS</div>Direct handover Settings for MS</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-hotsa">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.msActivateHandoverToSalesAgent"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.activateHandoverToSalesAgent? tooltips.activateHandoverToSalesAgent: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Handover to sales agent</small>
              </CRow>
            </CCol>
          </CRow>
          <CRow v-if="vendor.msActivateHandoverToSalesAgent">
            <CCol sm="3">
              <multiselect
                  data-sel="slct-dht"
                  :disabled="!allowEdit"
                  v-model="vendor.msDirectHandoverTemplate"
                  @input="updateItem"
                  :options="['ms_direct_handover_massivhaus']"
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select handover to sales agent email template "
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.directHandoverTemplate? tooltips.directHandoverTemplate: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Handover to sales agent email template</small>
              </CRow>
            </CCol>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-pdfid"
                  :readonly="!allowEdit"
                  v-model="vendor.msFactSheetId"
                  v-on:change="updateItem"
                  placeholder="Enter factsheet ID"
                  description="Factsheet ID"
                  @input="setDefaultFactsheetValue"
                  v-c-tooltip="{content: tooltips.factSheetId? tooltips.factSheetId: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"><div class="badge-pw">PW</div>Sales Agent App Settings for PW</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-nosaapp">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.disableSalesAgentApp"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.disableSalesAgentApp? tooltips.disableSalesAgentApp: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Disable Sales Agent App Functionality</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-nosaapp-fb">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.salesAgentAppDisableEmailFeedbackButtons"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.salesAgentAppDisableEmailFeedbackButtons? tooltips.salesAgentAppDisableEmailFeedbackButtons: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Disable Sales Agent App Email Feedback
                  Buttons</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-nosaapp-su">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.salesAgentAppDisableEmailSignupLink"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.salesAgentAppDisableEmailSignupLink? tooltips.salesAgentAppDisableEmailSignupLink: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Disable Sales Agent App Email Signup
                  Link</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CRow class="justify-content-center switch" data-sel="btn-nosaapp-oor">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.salesAgentAppDisableOpenOpportunityReminderEmail"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.salesAgentAppDisableOpenOpportunityReminderEmail? tooltips.salesAgentAppDisableOpenOpportunityReminderEmail: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Disable Sales Agent App Open Opportunity
                  Reminder
                  Email</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader
            v-c-tooltip="{content: tooltips.msHandoverRegions? tooltips.msHandoverRegions: '', delay: tooltipDelay}"
            color="secondary"
        >
          <div class="badge-ms">MS</div>
          {{ this.vendor.msHandoverRegions ? this.vendor.msHandoverRegions.length : 0 }} Handover regions
          for MS
        </CCardHeader>
        <CCardBody data-sel="zips-hor">
          <PostalCodeSelector
              v-bind:country="vendor? vendor.operatesIn: null"
              v-bind:postal-codes-in="vendor? vendor.msHandoverRegions: null"
              v-on:update-item="(e) => {this.vendor.msHandoverRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"><div class="badge-ms">MS</div>Handover Formats for MS</CCardHeader>
        <CCardBody>
          <CRow class="handover-spec-row">
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-msho-inst">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.msSendHtmlInstantHandover"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.msSendHtmlInstantHandover? tooltips.msSendHtmlInstantHandover: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS instant HTML email handover</small>
              </CRow>
            </CCol>
            <CCol sm="10" v-if="vendor.msSendHtmlInstantHandover">
              <CRow>
                <CCol sm="3">
                  <multiselect
                      data-sel="slct-msho-inst-tmpl"
                      :disabled="!allowEdit"
                      v-model="vendor.msHtmlInstantHandoverTemplate"
                      @input="updateItem"
                      :options=this.msHtmlInstantHandoverTemplateOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select template"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverTemplate? tooltips.msHtmlInstantHandoverTemplate: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS instant HTML handover email
                      template</small>
                  </CRow>
                </CCol>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-inst-email"
                      :disabled="!allowEdit"
                      v-model="vendor.msHtmlInstantHandoverEmails"
                      @input="updateItem"
                      :options="msHtmlInstantHandoverEmails"
                      :taggable="true"
                      tag-placeholder="Add this email"
                      placeholder="Add MS instant HTML email handover recipients"
                      :multiple="true"
                      @tag="addMsHtmlInstantHandoverEmail"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverEmails? tooltips.msHtmlInstantHandoverEmails: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS instant HTML handover email
                      recipients</small>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <CInput
                      data-sel="ipt-msho-sbjt"
                      v-model="vendor.msHtmlInstantHandoverEmailSubject"
                      v-on:change="updateItem"
                      placeholder="Enter email subject"
                      description="MS instant HTML handover email subject"
                      tooltip-feedback
                      :readonly="!allowEdit"
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverEmailSubject? tooltips.msHtmlInstantHandoverEmailSubject: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow class="handover-spec-row">
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-msho-csv">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.msSendCsvHandover"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.msSendCsvHandover? tooltips.msSendCsvHandover: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV email handover</small>
              </CRow>
            </CCol>
            <CCol sm="10" v-if="vendor.msSendCsvHandover">
              <CRow>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-csv-scdl"
                      :disabled="!allowEdit"
                      v-model="vendor.msCsvHandoverSchedule"
                      @input="updateItem"
                      :options=this.msCsvHandoverScheduleOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select schedule"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msCsvHandoverSchedule? tooltips.msCsvHandoverSchedule: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS CSV handover email schedule</small>
                  </CRow>
                </CCol>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-csv-scdl"
                      :disabled="!allowEdit"
                      v-model="vendor.msCsvHandoverFormat"
                      @input="updateItem"
                      :options=this.msCsvHandoverFormatOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select format"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msCsvHandoverFormat? tooltips.msCsvHandoverFormat: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS CSV handover email CSV
                      format</small>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow class="handover-spec-row">
            <CCol sm="3" v-if="vendor.msSendCsvHandover">
              <multiselect
                  data-sel="slct-msho-csv-tmpl"
                  :disabled="!allowEdit"
                  v-model="vendor.msCsvHandoverTemplate"
                  @input="updateItem"
                  :options=this.msCsvHandoverTemplateOptions
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select template"
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.msCsvHandoverTemplate? tooltips.msCsvHandoverTemplate: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV handover email template</small>
              </CRow>
            </CCol>
            <CCol sm="5" v-if="vendor.msSendCsvHandover">
              <multiselect
                  data-sel="slct-msho-inst-email"
                  :disabled="!allowEdit"
                  v-model="vendor.msCsvHandoverEmails"
                  @input="updateItem"
                  :options="msCsvHandoverEmails"
                  :taggable="true"
                  tag-placeholder="Add this email"
                  placeholder="Add MS CSV email handover recipients"
                  :multiple="true"
                  @tag="addMsCsvHandoverEmail"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.msCsvHandoverEmails? tooltips.msCsvHandoverEmails: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV handover email recipients</small>
              </CRow>
            </CCol>
            <CCol sm="4" v-if="vendor.msSendCsvHandover">
              <CInput
                  data-sel="ipt-msho-sbjt"
                  v-model="vendor.msCsvHandoverEmailSubject"
                  v-on:change="updateItem"
                  placeholder="Enter email subject"
                  description="MS CSV handover email subject"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.msCsvHandoverEmailSubject? tooltips.msCsvHandoverEmailSubject: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader
            v-c-tooltip="{content: tooltips.imHandoverRegions? tooltips.imHandoverRegions: '', delay: tooltipDelay}"
            color="secondary"
        >
          <div class="badge-im">IM</div>
          {{ this.vendor.imHandoverRegions ? this.vendor.imHandoverRegions.length : 0 }} Handover regions for IM
        </CCardHeader>
        <CCardBody data-sel="zips-hor">
          <PostalCodeSelector
              v-bind:country="vendor? vendor.operatesIn: null"
              v-bind:postal-codes-in="vendor? vendor.imHandoverRegions: null"
              v-on:update-item="(e) => {this.vendor.imHandoverRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
    </CCollapse>

    <FormDetailSection
        data-sel="btn-sctn-rep"
        section="Reporting"
        v-bind:showSection="showReporting"
        v-on:toggle="toggleReporting"
    />
    <CCollapse
        :show.sync="showReporting"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Vendor Reporting Settings
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <multiselect
                  data-sel="slct-ao"
                  :disabled="!allowEdit"
                  v-model="vendor.accountOwner"
                  @input="updateItem"
                  :options="this.accountOwners? this.accountOwners.data? this.accountOwners.data: []: []"
                  :show-labels="true"
                  :customLabel="accountOwnerLabel"
                  track-by="nid"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  select-label="Select"
                  deselect-label="Required field"
                  placeholder="Select account owner"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.accountOwner? tooltips.accountOwner: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Account owner</small>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <ReportRecipientAdd/>
            <CCol
                sm="2"
                class="text-left"
                v-if="allowEdit"
            >
              <CButton
                  color="info"
                  variant="outline"
                  @click="openAddRecipientModal"
              >
                New recipient
              </CButton>
            </CCol>
            <CCol :sm="allowEdit? 5: 6">
              <multiselect
                  data-sel="slct-mvrcpt"
                  class="multiselect-recipients"
                  :disabled="!allowEdit"
                  @input="updateItem"
                  v-model="vendor.monthlyVendorReportRecipients"
                  :options="this.reportRecipients? this.reportRecipients.data? this.reportRecipients.data: []: []"
                  :show-labels="true"
                  label="email"
                  track-by="email"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :allow-empty="true"
                  select-label="Add"
                  deselect-label="Remove"
                  placeholder="Add recipients"
                  v-on:search-change="updateReportRecipientQ"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.monthlyVendorReportRecipients? tooltips.monthlyVendorReportRecipients: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Monthly vendor report recipients</small>
              </CRow>
            </CCol>
            <CCol :sm="allowEdit? 5: 6">
              <multiselect
                  data-sel="slct-lfrcpt"
                  class="multiselect-recipients"
                  :disabled="!allowEdit"
                  @input="updateItem"
                  v-model="vendor.leadFeedbackReportRecipients"
                  :options="this.reportRecipients? this.reportRecipients.data? this.reportRecipients.data: []: []"
                  :show-labels="true"
                  label="email"
                  track-by="email"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :allow-empty="true"
                  select-label="Add"
                  deselect-label="Remove"
                  placeholder="Add recipients"
                  v-on:search-change="updateReportRecipientQ"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.leadFeedbackReportRecipients? tooltips.leadFeedbackReportRecipients: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Lead feedback report recipients</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

    </CCollapse>

    <FormDetailSection
        data-sel="btn-sctn-xcl"
        section="Exclusive Leads"
        v-bind:showSection="showExclusive"
        v-on:toggle="toggleExclusive"
    />
    <CCollapse
        :show.sync="showExclusive"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Exclusive Leads Settings
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-rep-inst">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.exclusiveLeadsSendInstantReporting"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.exclusiveLeadsSendInstantReporting? tooltips.exclusiveLeadsSendInstantReporting: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">EX instant email handover</small>
              </CRow>
            </CCol>
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-rep-inst">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.exclusiveLeadsSendDailyReporting"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.exclusiveLeadsSendDailyReporting? tooltips.exclusiveLeadsSendDailyReporting: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">EX daily email handover</small>
              </CRow>
            </CCol>
            <CCol sm="2">
              <CInput
                  data-sel="ipt-xcl-clnt"
                  v-model="vendor.exclusiveLeadsClientNid"
                  v-on:change="updateItem"
                  placeholder="Enter client NID"
                  description="Exclusive leads client NID"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.exclusiveLeadsClientNid? tooltips.exclusiveLeadsClientNid: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="4">
              <multiselect
                  data-sel="slct-xcl-af"
                  :disabled="!allowEdit"
                  v-model="vendor.exclusiveLeadsAttachmentFormat"
                  @input="updateItem"
                  :options=this.exclusiveLeadsAttachmentFormatOptions
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select template"
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.exclusiveLeadsAttachmentFormat? tooltips.exclusiveLeadsAttachmentFormat: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Exclusive leads handover email attachment</small>
              </CRow>
            </CCol>
            <CCol sm="6">
              <multiselect
                  data-sel="slct-xcl-inst-email"
                  :disabled="!allowEdit"
                  v-model="vendor.exclusiveLeadsHandoverEmails"
                  @input="updateItem"
                  :options="exclusiveLeadsHandoverEmails"
                  :taggable="true"
                  tag-placeholder="Add this email"
                  placeholder="Add exclusive leads email handover recipients"
                  :multiple="true"
                  @tag="addExclusiveHandoverEmail"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.exclusiveLeadsHandoverEmails? tooltips.exclusiveLeadsHandoverEmails: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Exclusive leads handover email recipients</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCollapse>

    <FormDetailSection
        data-sel="btn-sctn-im"
        section="Immowelt"
        v-bind:showSection="showImmowelt"
        v-on:toggle="toggleImmowelt"
    />
    <CCollapse
        :show.sync="showImmowelt"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Immowelt Settings
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-im-prdid"
                  v-model="vendor.imProductId"
                  v-on:change="updateItem"
                  placeholder="Enter product ID"
                  description="IM product ID"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.imProductId? tooltips.imProductId: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-im-ctlgid"
                  v-model="vendor.imCatalogueId"
                  v-on:change="updateItem"
                  placeholder="Enter catalogue ID"
                  description="IM catalogue ID"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.imCatalogueId? tooltips.imCatalogueId: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-im-lpid"
                  v-model="vendor.imLandingPageId"
                  v-on:change="updateItem"
                  placeholder="Enter landing page ID"
                  description="IM landing page ID"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.imLandingPageId? tooltips.imLandingPageId: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="3">
              <multiselect
                  :disabled="!allowEdit"
                  v-model="vendor.imSalesSegment"
                  @input="updateItem"
                  :options="['national', 'regional']"
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select IM Sales Segment"
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.imSalesSegment? tooltips.imSalesSegment: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">IM Sales Segment</small>
              </CRow>
            </CCol>
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-im-prslct">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="vendor.imPreselected"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.imPreselected? tooltips.imPreselected: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">IM Preselected</small>
              </CRow>
            </CCol>

          </CRow>
          <CRow>
            <ReportRecipientAdd/>
            <CCol
                sm="2"
                class="text-left"
                v-if="allowEdit"
            >
              <CButton
                  color="info"
                  variant="outline"
                  @click="openAddRecipientModal"
              >
                New recipient
              </CButton>
            </CCol>
            <CCol :sm="allowEdit? 5: 6">
              <multiselect
                  data-sel="slct-immvrcpt"
                  class="multiselect-recipients"
                  :disabled="!allowEdit"
                  @input="updateItem"
                  v-model="vendor.imMonthlyVendorReportRecipients"
                  :options="this.reportRecipients? this.reportRecipients.data? this.reportRecipients.data: []: []"
                  :show-labels="true"
                  label="email"
                  track-by="email"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :allow-empty="true"
                  select-label="Add"
                  deselect-label="Remove"
                  placeholder="Add recipients"
                  v-on:search-change="updateReportRecipientQ"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.imMonthlyVendorReportRecipients? tooltips.imMonthlyVendorReportRecipients: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">IM Monthly vendor report recipients</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCollapse>
  </div>
</template>

<script>
import gql from "graphql-tag";
import FormDetailSection from "@/components/common/FormDetailSection";
import PostalCodeSelector from "@/apps/partners/components/PostalCodeSelector";
import ReportRecipientAdd from "@/apps/partners/components/ReportRecipientAdd";
import VendorTooltips from "@/apps/partners/tooltips/vendor_tooltips";
import AddressForm from "@/apps/partners/components/AddressForm";

const _ = require('lodash')

const queryCountries = gql`
query {
  countries(order: "name") {
  data {
  nid
  name
  englishName
  nutsCode
  }
  }
}
`
const queryFranchiseHeadquarters = gql`
query franchiseHeadquarters($q: String){
  franchiseHeadquarters(q: $q, order: "name") {
  data {
  nid
  shortName
  }
  }
}
`
const queryAccountOwners = gql`
query {
  accountOwners(order: "lastName") {
  data{
  nid
  firstName
  lastName
  }
   }
}
`
const queryReportRecipients = gql`
query reportRecipients($q: String){
  reportRecipients(q: $q, order: "email") {
  data {
  nid
  email
  }
  }
}
`

export default {
  name: "VendorForm",
  props: ["vendorIn"],
  components: {
    FormDetailSection,
    PostalCodeSelector,
    ReportRecipientAdd,
    AddressForm
  },
  data() {
    return {
      tooltips: VendorTooltips,
      tooltipDelay: 500,
      countries: [],
      franchiseHeadquarters: [],
      franchiseHeadquartersQ: null,
      reportRecipients: [],
      reportRecipientQ: null,
      showContact: false,
      showBranding: false,
      showPortfolio: false,
      showHandover: false,
      showHandoverToRegions: false,
      showMsHandover: false,
      showReporting: false,
      showFranchise: false,
      showExclusive: false,
      showImmowelt: false,
      scoreOptions: ["none", "low", "medium", "high"],
      instantReportingOptions: ['vendor_no_prequal', 'vendor_prequal', 'vendor_prequal_ppc', 'vendor_prequal_ppc_exid'],
      msHtmlInstantHandoverTemplateOptions: ['ms_html_instant_handover', 'ms_html_instant_handover_no_domain'],
      msCsvHandoverScheduleOptions: ['instant', 'daily'],
      msCsvHandoverFormatOptions: ['massivhaus_csv', 'hanse_csv', 'hebel_csv', 'gussek_csv', 'fingerhut_csv'],
      msCsvHandoverTemplateOptions: ['ms_csv_handover_massivhaus', 'ms_csv_handover_no_domain'],
      exclusiveLeadsAttachmentFormatOptions: ['ex_hanse_json', 'ex_heinz_von_heiden_csv', 'ex_weber_csv', 'unified_phone_default_csv'],
      instantReportingApiPresetOptions: [
        'town_and_country', 'danhaus', 'planoa', 'finanzen_de', 'schwabenhaus', 'fingerhaus', 'haas', 'kagebau',
        'fh_weiss', 'iwt_finance_service', 'elk', 'aviv_seller', 'traumhausbegleiter', 'frammelsberger',
        'eco_system_haus'
      ],
      dailyReportingEmails: [],
      instantReportingEmails: [],
      anonymizationRequestEmails: [],
      msHtmlInstantHandoverEmails: [],
      msCsvHandoverEmails: [],
      leadDuplicateReportingEmails: [],
      exclusiveLeadsHandoverEmails: [],
    }
  },
  methods: {
    setDefaultFactsheetValue(event) {
      if (event.target.value === '') {
        this.vendor.factSheetId = '1PzAtQMtfmKL1tPTKwm_HaKUdcCKZMIjoXQgEMBssT2g';
      } else {
        this.vendor.factSheetId = event.target.value;
      }
      this.updateItem();
    },
    setDefaultDirectHandoverTemplateValue(event) {
      if (event.target.value === '') {
        this.vendor.directHandoverTemplate = 'prequal_2020';
      } else {
        this.vendor.directHandoverTemplate = event.target.value;
      }
      this.updateItem();
    },
    addDailyReportingEmail(email) {
      this.dailyReportingEmails.push(email);
      this.vendor.dailyReportingEmails.push(email);
      this.updateItem();
    },
    addInstantReportingEmail(email) {
      this.instantReportingEmails.push(email);
      this.vendor.instantReportingEmails.push(email);
      this.updateItem();
    },
    addAnonymizationRequestEmail(email) {
      this.anonymizationRequestEmails.push(email);
      this.vendor.anonymizationRequestEmails.push(email);
      this.updateItem();
    },
    addMsHtmlInstantHandoverEmail(email) {
      this.msHtmlInstantHandoverEmails.push(email);
      this.vendor.msHtmlInstantHandoverEmails.push(email);
      this.updateItem();
    },
    addMsCsvHandoverEmail(email) {
      this.msCsvHandoverEmails.push(email);
      this.vendor.msCsvHandoverEmails.push(email);
      this.updateItem();
    },
    addLeadDuplicateReportingEmail(email) {
      this.leadDuplicateReportingEmails.push(email);
      this.vendor.leadDuplicateReportingEmails.push(email);
      this.updateItem();
    },
    addExclusiveHandoverEmail(email) {
      this.exclusiveLeadsHandoverEmails.push(email);
      this.vendor.exclusiveLeadsHandoverEmails.push(email);
      this.updateItem();
    },
    updateItem() {
      const data = _.cloneDeep(_.omit(this.vendor, [
          'logo.__typename',
          'officeProfilePicture.__typename',
      ]))
      //multiselect object
      data.operatesIn = this.vendor.operatesIn ? {nid: this.vendor.operatesIn.nid} : null;
      data.franchiseHq = this.vendor.franchiseHq ? {nid: this.vendor.franchiseHq.nid} : null;
      data.accountOwner = this.vendor.accountOwner ? {nid: this.vendor.accountOwner.nid} : null;
      data.country = this.vendor.country ? {nid: this.vendor.country.nid} : null;
      data.postalCode = this.vendor.postalCode ? {nid: this.vendor.postalCode.nid} : null;
      data.monthlyVendorReportRecipients = this.vendor.monthlyVendorReportRecipients ? this.vendor.monthlyVendorReportRecipients.map(item => {
        return {nid: item.nid}
      }) : null;
      data.imMonthlyVendorReportRecipients = this.vendor.imMonthlyVendorReportRecipients ? this.vendor.imMonthlyVendorReportRecipients.map(item => {
        return {nid: item.nid}
      }) : null;
      data.leadFeedbackReportRecipients = this.vendor.leadFeedbackReportRecipients ? this.vendor.leadFeedbackReportRecipients.map(item => {
        return {nid: item.nid}
      }) : null;
      data.displayRegions = this.vendor.displayRegions ? this.vendor.displayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msDisplayRegions = this.vendor.msDisplayRegions ? this.vendor.msDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imDisplayRegions = this.vendor.imDisplayRegions ? this.vendor.imDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.handoverRegions = this.vendor.handoverRegions ? this.vendor.handoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msHandoverRegions = this.vendor.msHandoverRegions ? this.vendor.msHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imHandoverRegions = this.vendor.imHandoverRegions ? this.vendor.imHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.localPlacementRegionOverride = this.vendor.localPlacementRegionOverride ? this.vendor.localPlacementRegionOverride.map(item => {
        return item.numericPostalCode
      }) : null;
      this.$emit('update-item', data);
    },
    notNull(val) {
      return val ? val.length >= 1 : false
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    uploadLogo(input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.vendor.logo.base64 = e.target.result;
        this.vendor.logo.name = input[0].name;
        this.vendor.logo.nid = null;
        this.updateItem();
      };
      reader.readAsDataURL(input[0]);
    },
    uploadOfficeProfilePicture(input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.vendor.officeProfilePicture.base64 = e.target.result;
        this.vendor.officeProfilePicture.name = input[0].name;
        this.updateItem();
      };
      reader.readAsDataURL(input[0]);
    },
    validExternalID(externalId) {
      const re = /[A-Z0-9]{3}/;
      return re.test(externalId);
    },
    toggleContact() {
      this.showContact = !this.showContact
    },
    toggleBranding() {
      this.showBranding = !this.showBranding
    },
    togglePortfolio() {
      this.showPortfolio = !this.showPortfolio
    },
    toggleHandover() {
      this.showHandover = !this.showHandover
    },
    toggleReporting() {
      this.showReporting = !this.showReporting
    },
    toggleExclusive() {
      this.showExclusive = !this.showExclusive
    },
    toggleImmowelt() {
      this.showImmowelt = !this.showImmowelt
    },
    addEmail(email, field) {
      this.vendor[field] = this.vendor[field] ? this.vendor[field].filter(item => item !== email) : [email]
    },
    updateVendorQ(event) {
      this.vendorQ = event
    },
    updateReportRecipientQ(event) {
      this.reportRecipientQ = event
    },
    handoverToDisplayRegions() {
      this.vendor.displayRegions = this.vendor.handoverRegions ? _.cloneDeep(this.vendor.handoverRegions) : [];
      this.updateItem();
    },
    openAddRecipientModal() {
      this.$store.commit('set', ['addReportRecipientModalShow', true]);
    },
    updateAddress(address) {
      this.vendor.addressLine1 = address.addressLine1;
      this.vendor.addressLine2 = address.addressLine2;
      this.vendor.postalCode = address.postalCode;
      this.vendor.city = address.city;
      this.vendor.country = address.country;
      this.updateItem();
    },
    accountOwnerLabel({firstName, lastName}) {
      return `${firstName} ${lastName}`
    },
  },
  apollo: {
    countries: {
      query: queryCountries,
      error(error) {
        this.error = error.message;
      }
    },
    franchiseHeadquarters: {
      query: queryFranchiseHeadquarters,
      variables() {
        return {
          q: this.franchiseHeadquartersQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
    },
    accountOwners: {
      query: queryAccountOwners,
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    },
    reportRecipients: {
      query: queryReportRecipients,
      variables() {
        return {
          q: this.reportRecipientQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    }
  },
  computed: {
    vendor() {
      return this.vendorIn ? this.vendorIn : {}
    },
    wagtailUrl() {
      return `https://www.fertighaus.de/admin/internal/vendor/?nid=${this.vendor.nid}`
    },
    address() {
      return {
        addressLine1: this.vendor ? this.vendor.addressLine1 : null,
        addressLine2: this.vendor ? this.vendor.addressLine2 : null,
        postalCode: this.vendor ? this.vendor.postalCode : null,
        city: this.vendor ? this.vendor.city : null,
        country: this.vendor ? this.vendor.country : null,
      }
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set() {
      }
    }
  },
  watch: {
    vendorIn() {
      // reset enum selects
      this.updateItem();
    }
  }
}
</script>

<style scoped>
.badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multiselect {
  border-width: 2pt;
  list-style-type: none;
  margin-bottom: 1pt;
  z-index: 1000;
}

.switch-prefix-col {
  margin-top: 0.5rem;
  text-align: left;
}

.switch-col {
  margin-top: 0.25rem;
  align-content: start;
}

.switch-row {
  height: 2.25rem;
}

.multiselect-recipients {
  margin-bottom: 2pt;
  overflow: auto;
  height: auto;
}

.handover-spec-row {
  min-height: 6rem;
}

.active-info-box {
  font-size: 10px;
  padding: 0;
}

.multiselect--active {
  border-width: 2pt;
  list-style-type: none;
  margin-bottom: 1pt;
  overflow: visible;
}
</style>
